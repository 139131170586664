import { Signer } from 'casper-js-sdk';
import { Axios, Canceler } from '../../../core/axios';
import {
  CONNECTION_TYPES,
  CONNECTED_ACCOUNT_STORAGE_PATH,
} from '../../../constants/settings';
import {
  setLocalStorageValue,
  getLocalStorageValue,
} from '../../../services/localStorage';
import * as actions from '../';
import api from '../../../core/api';

const cacheLoginInfoToLocalStorage = (publicKey, loginOptions) => {
  setLocalStorageValue(
    'account',
    CONNECTED_ACCOUNT_STORAGE_PATH,
    { publicKey, loginOptions },
    'set',
  );
};

/**
 * @param {string} publicKey
 * @param {string} connectionType
 * @returns {object}
 */
export const setPublicKey = (publicKey, loginOptions = {}) => {
  //Cache public key and login options
  cacheLoginInfoToLocalStorage(publicKey, loginOptions);

  return (dispatch) => {
    dispatch(actions.setUserAddress({ publicKey, loginOptions }));
  };
};

export const updatePublicKeyFromSigner = () => {
  return async (dispatch) => {
    let publicKey;
    try {
      publicKey = await Signer.getActivePublicKey();
      dispatch(
        setPublicKey(publicKey, {
          connectionType: CONNECTION_TYPES.casperSigner,
        }),
      );
    } catch (error) {
      dispatch(actions.updateConnectStatus({ isLocked: true }));
    }
  };
};

export const handleUnlockSigner = ({ isUnlocked, activeKey }) => {
  return (dispatch) => {
    dispatch(actions.updateLockStatus({ isUnlocked }));
    dispatch(
      setPublicKey(activeKey, {
        connectionType: CONNECTION_TYPES.casperSigner,
      }),
    );
  };
};

export const getConnectedAccountFromLocalStorage = () => {
  return (dispatch) => {
    const connectedAccount = getLocalStorageValue(
      'account',
      CONNECTED_ACCOUNT_STORAGE_PATH,
    );
    if (connectedAccount && connectedAccount.publicKey) {
      dispatch(
        setPublicKey(connectedAccount.publicKey, connectedAccount.loginOptions),
      );
      return connectedAccount.publicKey;
    }
    return undefined;
  };
};

export const getUserInfo = (publicKey) => async (dispatch) => {
  dispatch(actions.fetchUserInfo.request(Canceler.token));

  try {
    const { data } = await Axios.get(`${api.user}/${publicKey}`, {
      cancelToken: Canceler.token,
    });

    dispatch(actions.fetchUserInfo.success(data));
  } catch (err) {
    dispatch(actions.fetchUserInfo.failure(err));
  }
};
