import { getType } from 'typesafe-actions';
import * as actions from '../actions';
import {
  initEntityState,
  entityLoadingStarted,
  entityLoadingSucceeded,
  entityLoadingFailed,
} from '../utils';

export const defaultState = {
  userDetails: initEntityState({}),
};

export default function userReducer(
  state = {
    publicKey: '',
    loginOptions: {},
    ...defaultState,
  },
  action,
) {
  const { payload } = action;
  switch (action.type) {
    case getType(actions.setUserAddress):
      return {
        ...state,
        publicKey: payload.publicKey,
        loginOptions: payload.loginOptions || {},
      };
    //details
    case getType(actions.fetchUserInfo.request):
      return {
        ...state,
        userDetails: entityLoadingStarted(state.userDetails, action.payload),
      };
    case getType(actions.fetchUserInfo.success):
      return {
        ...state,
        userDetails: entityLoadingSucceeded(state.userDetails, action.payload),
      };
    case getType(actions.fetchUserInfo.failure):
      return { ...state, userDetails: entityLoadingFailed(state.userDetails) };
    default:
      return state;
  }
}
