import React from 'react';
import { useSelector } from 'react-redux';
import { getUserInfo } from '../../store/selectors/user';
import Clock from './Clock';

const NFTReview = ({ thumb, title }) => {
  const { avatar } = useSelector(getUserInfo);
  return (
    <div className="col-lg-3 col-sm-6 col-xs-12">
      <h5>Preview item</h5>
      <div className="nft__item m-0">
        <div className="de_countdown">
          <Clock deadline="December, 30, 2021" />
        </div>
        <div className="author_list_pp">
          <span>
            <img className="lazy" src={avatar} alt="" />
            <i className="fa fa-check"></i>
          </span>
        </div>
        <div className="nft__item_wrap">
          <span>
            <img
              src={thumb || './img/collections/coll-item-3.jpg'}
              id="get_file_2"
              className="lazy nft__item_preview"
              alt=""
            />
          </span>
        </div>
        <div className="nft__item_info">
          <span>
            <h4>{title || 'Pinky Ocean'}</h4>
          </span>
          <div className="nft__item_price">
            0.08 CSPR<span>1/20</span>
          </div>
          <div className="nft__item_action">
            <span>Place a bid</span>
          </div>
          <div className="nft__item_like">
            <i className="fa fa-heart"></i>
            <span>50</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NFTReview;
