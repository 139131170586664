import { getBase64IdentIcon } from '../../helpers/identicon';

export const MOTE_RATE = 1000000000;
export const convertBalanceFromHex = (balanceHex) => {
  const balance = parseInt(balanceHex);

  return moteToCspr(balance) || 0;
};

/**
 * Given a balance in CSPR, return the balance in MOTE
 * @param balanceInCSPR - The balance of the account in CSPR.
 * @returns The balance in CSPR.
 */
export const moteToCspr = (balanceInCSPR) => {
  return parseFloat(balanceInCSPR / MOTE_RATE);
};

export const getPublicKey = (store) => {
  return store.user.publicKey;
};

export const getLoginOptions = ({ user }) => {
  return user.loginOptions || {};
};

export const getUserInfo = (store) => {
  const publicKey = store.user.publicKey;
  const hexBalance = store.user.userDetails.data.balance
    ? store.user.userDetails.data.balance.hex
    : 0;
  return {
    publicKey: publicKey,
    avatar: getBase64IdentIcon(publicKey),
    userName: 'unknown',
    social: '@unknown',
    balance: moteToCspr(hexBalance),
  };
};
