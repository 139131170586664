import React, { memo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Skeleton from '../../components/common/Skeleton';
import * as selectors from '../../../store/selectors';
import * as actions from '../../../store/actions/thunks';
import NftCard from '../../components/NftCard';
import './MyNFT.scss';

//react functional component
const MyNFTs = ({ authorId = null, status }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [height, setHeight] = useState(0);

  const myNFTs = useSelector(selectors.getMyNFTs);
  const { loading } = useSelector(selectors.myNFTs);
  const onImgLoad = ({ target: img }) => {
    let currentHeight = height;
    if (currentHeight < img.offsetHeight) {
      setHeight(img.offsetHeight);
    }
  };

  const onClickNFT = (nft) => {
    navigate(`/my-nft/${nft.contractAddress}/${nft.id}`);
  };

  useEffect(() => {
    if (authorId) {
      dispatch(actions.fetchMyNFTs(authorId, status));
    }
  }, [dispatch, authorId, status]);

  return (
    <div className="row my-nft">
      {loading ? (
        <Skeleton count={4} width={252} height={414} className="skeleton" />
      ) : myNFTs && myNFTs.length ? (
        myNFTs.map((nft, index) => (
          <NftCard
            nft={nft}
            key={index}
            onImgLoad={onImgLoad}
            height={height}
            onClickNFT={onClickNFT}
          />
        ))
      ) : null}
    </div>
  );
};

export default memo(MyNFTs);
