import React, { memo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Slider from 'react-slick';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import * as selectors from '../../store/selectors';
import { fetchNftsBreakdown } from '../../store/actions/thunks';
import Clock from './Clock';
import { carouselNew } from './constants';

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

const CarouselNewRedux = () => {
  const dispatch = useDispatch();
  const nftsState = useSelector(selectors.nftBreakdownState);
  const nfts = nftsState.data ? nftsState.data : [];
  const navigate = useNavigate();

  const [height, setHeight] = useState(0);

  const onImgLoad = ({ target: img }) => {
    let currentHeight = height;
    if (currentHeight < img.offsetHeight) {
      setHeight(img.offsetHeight);
    }
  };

  useEffect(() => {
    dispatch(fetchNftsBreakdown());
  }, [dispatch]);

  return (
    <div className="nft">
      <Slider {...carouselNew}>
        {nfts &&
          nfts.map((nft, index) => (
            <div
              className="itm"
              index={index + 1}
              key={index}
              onClick={() => navigate(`/ItemDetail/${nft.id}`)}
            >
              <div className="d-item">
                <div className="nft__item">
                  {nft.deadline && (
                    <div className="de_countdown">
                      <Clock deadline={nft.deadline} />
                    </div>
                  )}
                  <div className="author_list_pp">
                    <span onClick={() => window.open('/home1', '_self')}>
                      <img
                        className="lazy"
                        src={nft.author.avatar.url}
                        alt=""
                      />
                      <i className="fa fa-check"></i>
                    </span>
                  </div>
                  <div
                    className="nft__item_wrap"
                    style={{ height: `${height}px` }}
                  >
                    <Outer>
                      <span>
                        <img
                          src={nft.preview_image.url}
                          className="lazy nft__item_preview"
                          onLoad={onImgLoad}
                          alt=""
                        />
                      </span>
                    </Outer>
                  </div>
                  <div className="nft__item_info">
                    <span onClick={() => window.open('/#', '_self')}>
                      <h4>{nft.title}</h4>
                    </span>
                    <div className="nft__item_price">
                      {nft.price} CSPR
                      <span>
                        {nft.bid}/{nft.max_bid}
                      </span>
                    </div>
                    <div className="nft__item_action">
                      <span>
                        {nft.status === 'on_auction'
                          ? 'Place a bid'
                          : 'Buy now'}
                      </span>
                    </div>
                    <div className="nft__item_like">
                      <i className="fa fa-heart"></i>
                      <span>{nft.likes}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </Slider>
    </div>
  );
};

export default memo(CarouselNewRedux);
