import React from 'react';
import { createGlobalStyle } from 'styled-components';
import ColumnAuctionRedux from '../components/ColumnAuctionRedux';

const GlobalStyles = createGlobalStyle`
  .greyscheme .de_countdown{
            position: relative;
        }
`;

const Auction = () => (
  <>
    <GlobalStyles />
    <section className="jumbotron breadcumb no-bg">
      <div className="mainbreadcumb">
        <div className="container">
          <div className="row m-10-hor">
            <div className="col-12">
              <h1 className="text-center">Live Auction</h1>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="container">
      <ColumnAuctionRedux />
    </section>
  </>
);
export default Auction;
