import { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateConnectStatus, updateLockStatus } from '../../store/actions';
import {
  handleUnlockSigner,
  updatePublicKeyFromSigner,
} from '../../store/actions/thunks/user';
import {
  isConnectedCasper,
  getSignerStatus,
} from '../../store/selectors/signer';
import { getPublicKey, getLoginOptions } from '../../store/selectors/user';
import { connectCasperSigner } from '../../services/casperServices';
import { CONNECTION_TYPES } from '../../constants/settings';

const SIGNER_EVENTS = {
  connected: 'signer:connected',
  disconnected: 'signer:disconnected',
  initialState: 'signer:initialState',
  tabUpdated: 'signer:tabUpdated',
  activeKeyChanged: 'signer:activeKeyChanged',
  locked: 'signer:locked',
  unlocked: 'signer:unlocked',
};

/**
 * This function is used to connect to the Casper Signer extension
 */
const useCasperSigner = () => {
  //Hook
  const dispatch = useDispatch();

  //Selector
  const publicKey = useSelector(getPublicKey);
  const { isConnected, isAvailable } = useSelector(getSignerStatus);
  const loginOptions = useSelector(getLoginOptions);

  //State
  const [errorMessage, setErrorMessage] = useState();
  const [isSignerInjected, setIsSignerInjected] = useState();

  //Function
  const dispatchUnlockSinger = useCallback(
    (event) => {
      dispatch(handleUnlockSigner(event.detail));
    },
    [dispatch],
  );

  const dispatchDisconnectedSinger = useCallback(() => {
    dispatch(updateLockStatus({ isUnlocked: false }));
  }, [dispatch]);

  const handleConnectCasper = () => {
    const connectMessage = connectCasperSigner();
    if (connectMessage) {
      setErrorMessage(connectMessage);
    }
  };

  const handleSignerInjected = () => {
    setIsSignerInjected(true);
  };

  //Effect
  useEffect(() => {
    [
      SIGNER_EVENTS.unlocked,
      SIGNER_EVENTS.activeKeyChanged,
      SIGNER_EVENTS.connected,
    ].forEach((event) => window.addEventListener(event, dispatchUnlockSinger));
    [SIGNER_EVENTS.locked, SIGNER_EVENTS.disconnected].forEach((event) =>
      window.addEventListener(event, dispatchDisconnectedSinger),
    );
    [SIGNER_EVENTS.initialState].forEach((event) =>
      window.addEventListener(event, handleSignerInjected),
    );

    return () => {
      [
        SIGNER_EVENTS.unlocked,
        SIGNER_EVENTS.activeKeyChanged,
        SIGNER_EVENTS.connected,
      ].forEach((event) =>
        window.removeEventListener(event, dispatchUnlockSinger),
      );
      [SIGNER_EVENTS.locked, SIGNER_EVENTS.disconnected].forEach((event) =>
        window.removeEventListener(event, dispatchDisconnectedSinger),
      );
      [SIGNER_EVENTS.initialState].forEach((event) =>
        window.removeEventListener(event, handleSignerInjected),
      );
    };
  });

  useEffect(() => {
    (async function () {
      if (
        !publicKey &&
        loginOptions.connectionType === CONNECTION_TYPES.casperSigner
      ) {
        const isConnected = await isConnectedCasper();
        dispatch(updateConnectStatus(isConnected));
        if (isConnected) {
          dispatch(updatePublicKeyFromSigner());
        }
      }
    })();
  }, [
    isConnected,
    dispatch,
    publicKey,
    isSignerInjected,
    loginOptions.connectionType,
  ]);

  return { connectionError: errorMessage, isAvailable, handleConnectCasper };
};

export default useCasperSigner;
