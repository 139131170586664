import React, { memo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import Clock from '../../components/Clock';
import * as selectors from '../../../store/selectors';
import * as actions from '../../../store/actions/thunks';
import { fetchMyNftDetail } from '../../../store/actions/thunks';
import NFTAttributes from '../../components/NFTAttributes';
import Skeleton from '../../components/common/Skeleton';
import { MiddleTruncatedText } from '../../components/common/MiddleTruncatedText';
import useSigner from '../../hooks/useSigner';
import { NFT_STATUS } from '../../../constants/egg';
import './MyNFTDetail.scss';

const MyNFTDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { nftSmartContract, nftId } = useParams();
  const { signMessage } = useSigner();

  const { publicKey } = useSelector(selectors.getUserInfo);
  const myNFTDetail = useSelector(selectors.getMyNFTDetail);
  const { loading, data } = useSelector(selectors.myNFTDetail);

  const [initPk] = useState(publicKey);

  useEffect(() => {
    if (publicKey && nftId && nftSmartContract) {
      dispatch(fetchMyNftDetail(nftId, nftSmartContract, publicKey));
    }
  }, [dispatch, nftId, nftSmartContract, publicKey]);

  useEffect(() => {
    if (initPk !== publicKey) {
      navigate(publicKey ? `/Author/${publicKey}` : '/');
    }
  }, [navigate, publicKey, initPk]);

  const onUpdate = async () => {
    const isHatching = myNFTDetail.status === NFT_STATUS.hatching;
    const signedMessage = await signMessage(
      `${isHatching ? 'Stop-Hatching' : 'Hatch'}-${nftId}-${nftSmartContract}`,
      publicKey,
    );

    if (isHatching) {
      dispatch(
        actions.stopHatching(signedMessage, nftId, nftSmartContract, publicKey),
      );
    } else {
      dispatch(
        actions.hatch(signedMessage, nftId, nftSmartContract, publicKey, data),
      );
    }
  };

  return (
    <>
      <section className="container">
        <div className="row mt-md-5 pt-md-4">
          <div className="col-md-6 egg-image">
            {loading ? (
              <Skeleton height="100%" />
            ) : (
              <>
                <img
                  src={myNFTDetail.image}
                  className="img-fluid img-rounded mb-sm-30"
                  alt=""
                />
                {myNFTDetail.status !== undefined && (
                  <div className="xp-status">
                    <div className="current-exp ">
                      <span className="color-white">
                        {myNFTDetail.exp} CSPR
                      </span>{' '}
                      rewards
                    </div>
                    <div className="xp-bar">
                      <div
                        className="xp"
                        style={{
                          width: `${myNFTDetail.nextLevelPercentage}%`,
                        }}
                      ></div>
                    </div>
                    <div className="level">
                      <div className="color-white">
                        Level{' '}
                        <span className="color-white">{myNFTDetail.level}</span>
                      </div>
                      <div>
                        <span className="small-text">
                          {myNFTDetail.expToNextLevel} CSPR
                        </span>{' '}
                        to{' '}
                        <span className="color-white">
                          {myNFTDetail.nextLevel}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="col-md-6">
            <div className="item_info">
              {myNFTDetail.item_type === 'on_auction' && (
                <>
                  Auctions ends in
                  <div className="de_countdown">
                    <Clock deadline={myNFTDetail.deadline} />
                  </div>
                </>
              )}
              <h2>{loading ? <Skeleton width={300} /> : myNFTDetail.title}</h2>
              {/* <div className="item_info_counts">
                <div className="item_info_type">
                  <i className="fa fa-image"></i>
                  {myNFTDetail.category}
                </div>
                <div className="item_info_views">
                  <i className="fa fa-eye"></i>
                  {myNFTDetail.views}
                </div>
                <div className="item_info_like">
                  <i className="fa fa-heart"></i>
                  {myNFTDetail.likes}
                </div>
              </div> */}
              <p>
                {loading ? <Skeleton width={300} /> : myNFTDetail.description}
              </p>

              <div className="d-flex flex-row">
                <div className="mr40">
                  <h6>Creator</h6>
                  <div className="item_author">
                    <div className={loading ? '' : 'author_list_pp'}>
                      {loading ? (
                        <Skeleton circle width={50} height={50} />
                      ) : (
                        <span>
                          <img
                            className="lazy"
                            src={myNFTDetail.creatorAvatar}
                            alt=""
                          />
                          <i className="fa fa-check"></i>
                        </span>
                      )}
                    </div>
                    <div className="author_list_info">
                      <span>
                        {loading ? (
                          <Skeleton width={100} />
                        ) : (
                          <MiddleTruncatedText>
                            {myNFTDetail.creator}
                          </MiddleTruncatedText>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <h6>Collection</h6>
                  <div className="item_author">
                    <div
                      className={loading ? '' : 'author_list_pp'}
                      title={myNFTDetail.contractAddress}
                    >
                      {loading ? (
                        <Skeleton circle width={50} height={50} />
                      ) : (
                        <span>
                          <img
                            className="lazy"
                            src={myNFTDetail.contractImage}
                            alt=""
                          />
                          <i className="fa fa-check"></i>
                        </span>
                      )}
                    </div>
                    <div className="author_list_info">
                      <span>
                        {loading ? (
                          <Skeleton width={100} />
                        ) : (
                          myNFTDetail.contractName
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="spacer-40"></div>

              <div className="de_tab">
                <ul className="de_nav">
                  <li className="active">
                    <span>Details</span>
                  </li>
                </ul>

                <div className="de_tab_content">
                  <div className="tab-1 onStep fadeIn">
                    <div className="d-block mb-3">
                      <div className="mr40">
                        <h6>Owner</h6>
                        <div className="item_author">
                          <div className={loading ? '' : 'author_list_pp'}>
                            {loading ? (
                              <Skeleton circle width={50} height={50} />
                            ) : (
                              <span>
                                <img
                                  className="lazy"
                                  src={myNFTDetail.ownerAvatar}
                                  alt=""
                                />
                                <i className="fa fa-check"></i>
                              </span>
                            )}
                          </div>
                          <div className="author_list_info">
                            <span>
                              {loading ? (
                                <Skeleton width={100} />
                              ) : (
                                <MiddleTruncatedText>
                                  {myNFTDetail.owner}
                                </MiddleTruncatedText>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-5">
                        {loading
                          ? new Array(3).fill().map((i, index) => {
                              return (
                                <div
                                  key={index}
                                  className="col-lg-4 col-md-6 col-sm-6"
                                >
                                  <Skeleton height={70} />
                                </div>
                              );
                            })
                          : myNFTDetail?.metadata?.map(
                              ({ name, value, rate }) => {
                                return (
                                  <div
                                    key={name}
                                    className="col-lg-4 col-md-6 col-sm-6"
                                  >
                                    <NFTAttributes
                                      name={name}
                                      value={value}
                                      rate={rate}
                                    />
                                  </div>
                                );
                              },
                            )}
                      </div>
                      <div className="d-flex flex-row mt-5">
                        {loading ? (
                          <Skeleton width={120} height={37} />
                        ) : (
                          myNFTDetail.action && (
                            <button
                              className="btn-main lead mb-5 mr15"
                              onClick={onUpdate}
                            >
                              {myNFTDetail.status === NFT_STATUS.hatching
                                ? 'Stop Hatching'
                                : 'Hatch'}
                            </button>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default memo(MyNFTDetail);
