import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonWrapper = ({ borderRadius, className, inline, ...props }) => {
  return (
    <SkeletonTheme
      baseColor="#8b949f"
      highlightColor="#a5b0bd"
      borderRadius={borderRadius || '0.5rem'}
      duration={2}
      className={className}
      inline={inline}
    >
      <Skeleton {...props} />
    </SkeletonTheme>
  );
};

export default SkeletonWrapper;
