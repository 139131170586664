import React from 'react';
import './NFTAttributes.scss';

const NFTAttributes = ({ name, value, rate, onRemove }) => {
  return (
    <div className="nft_attr">
      {typeof onRemove === 'function' && (
        <span
          aria-hidden="true"
          className="remove-icon icon_minus_alt2"
          onClick={(e) => {
            e.stopPropagation();
            onRemove(name);
          }}
        />
      )}
      <h5>{name?.toUpperCase()}</h5>
      <h4>{value}</h4>
      {rate && <span>{`${rate}% have this trait`}</span>}
    </div>
  );
};

export default NFTAttributes;
