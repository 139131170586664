import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserInfo } from '../../../store/selectors';
import { MiddleTruncatedText } from '../../components/common/MiddleTruncatedText';
import MyNFTs from './MyNFTs';

const TABS = [
  { name: 'My NFTs' },
  { name: 'Minting', status: 'minting' },
  { name: 'Hatching', status: 'hatching' },
];

const Author = () => {
  const [selectedTab, setSelectedTab] = useState(TABS[0]);
  const { publicKey, avatar, social, userName } = useSelector(getUserInfo);

  return (
    <>
      <section className="container no-bottom">
        <div className="row">
          <div className="spacer-double"></div>
          <div className="col-md-12">
            <div className="d_profile de-flex">
              <div className="de-flex-col">
                <div className="profile_avatar">
                  {avatar && <img src={avatar} alt="" />}
                  <i className="fa fa-check"></i>
                  <div className="profile_name">
                    <h4>
                      {userName}
                      <span className="profile_username">{social}</span>
                      <span id="wallet" className="profile_wallet">
                        <MiddleTruncatedText>{publicKey}</MiddleTruncatedText>
                      </span>
                      <button id="btn_copy" title="Copy Text">
                        Copy
                      </button>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container no-top">
        <div className="row">
          <div className="col-lg-12">
            <div className="items_filter">
              <ul className="de_nav text-left">
                {TABS.map((tab) => {
                  return (
                    <li
                      key={tab.name}
                      className={selectedTab.name === tab.name ? 'active' : ''}
                    >
                      <span
                        onClick={() => {
                          setSelectedTab(tab);
                        }}
                      >
                        {tab.name}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="onStep fadeIn">
          <MyNFTs
            shuffle
            showLoadMore={false}
            authorId={publicKey}
            status={selectedTab.status}
          />
        </div>
      </section>
    </>
  );
};
export default memo(Author);
