import React from 'react';

const PageTitle = ({ title }) => {
  return (
    <section className="jumbotron breadcumb no-bg">
      <div className="mainbreadcumb">
        <div className="container">
          <div className="row m-10-hor">
            <div className="col-12">
              <h1 className="text-center">{title}</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageTitle;
