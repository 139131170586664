import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import auth from '../core/auth';
import ScrollToTopBtn from './menu/ScrollToTop';
import Header from './menu/Header';
import Home from './pages/Home';
import Explore from './pages/Explore';
import RankingRedux from './pages/RankingRedux';
import Auction from './pages/Auction';
import HelpCenter from './pages/HelpCenter';
import ItemDetailRedux from './pages/ItemDetail';
import MyNFTDetail from './pages/Author/MyNFTDetail';
import Author from './pages/Author/Author';
import Wallet from './pages/WalletPage';
import News from './pages/News';
import NewsSingle from './pages/NewsSingle';
import Create from './pages/Create';
import Activity from './pages/Activity';
import Contact from './pages/Contact';
import Profile from './pages/Profile';
import Footer from './components/footer';
import { StyledHeader } from './Styles';

const GlobalStyles = createGlobalStyle`
  :root {
    scroll-behavior: unset;
  }
`;
const theme = 'GREY';

const ProtectedRoute = ({ children }) => {
  let location = useLocation();
  const isAuth = auth.getToken() !== null;

  return isAuth ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

const app = () => (
  <div className="wraper">
    <GlobalStyles />
    <StyledHeader theme={theme} />
    <Header />
    <div className="greyscheme">
      <Routes>
        <Route path="*" element={<Navigate to="/home" replace />} />
        <Route path="/Author">
          <Route
            path=":authorId"
            element={
              <ProtectedRoute>
                <Author />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="/Profile">
          <Route
            path=":authorId"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="/home" element={<Home />} />
        <Route element={<Explore />} path="/explorer" />
        <Route element={<RankingRedux />} path="/ranking" />
        <Route element={<Auction />} path="/Auction" />
        <Route element={<HelpCenter />} path="/helpcenter" />
        <Route element={<ItemDetailRedux />} path="/ItemDetail/:nftId" />
        <Route
          element={<MyNFTDetail />}
          path="/my-nft/:nftSmartContract/:nftId"
        />
        <Route element={<Author />} path="/Author/:authorId" />
        <Route element={<Wallet />} path="/wallet" />
        <Route element={<News />} path="/news" />
        <Route element={<NewsSingle />} path="/news/:blogId" />
        <Route element={<Create />} path="/create" />
        <Route element={<Activity />} path="/activity" />
        <Route element={<Contact />} path="/contact" />
      </Routes>
      <Footer />
    </div>
    <ScrollToTopBtn />
  </div>
);
export default app;
