import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { storeFile } from '../../store/actions/thunks';
import { isUploadingFile, getUserInfo } from '../../store/selectors';
import { getMintDeploy } from '../../services/nftServices';
import { useConfirmDeploy } from '../hooks/useConfirmDeploy';
import NFTReview from '../components/NFTReview';
import PageTitle from '../components/PageTitle';
import CreateForm from '../components/CreateNFT/CreateForm';
import { NFT_GATEWAY } from '../../constants/key';

const validateFormData = (formData) => {
  const { title } = formData;
  let errors = {};
  if (!title) {
    errors.title = 'Title is required';
  }
  return errors;
};

const Create = () => {
  const dispatch = useDispatch();
  const { executeDeploy, isDeploying: isMinting } = useConfirmDeploy();

  const [toastId] = useState('createNFTToastID');
  const [file, setFile] = useState();
  const [thumb, setThumb] = useState();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});

  const isUploading = useSelector(isUploadingFile);
  const userInfo = useSelector(getUserInfo);

  const onChange = (e) => {
    const files = e.currentTarget.files;
    setFile(files[0]);
  };

  const buildMintNFTDeploy = async () => {
    const cid = await storeNFT();
    toast.update(toastId, {
      render: 'Store file successfully!',
      type: 'success',
      isLoading: false,
      autoClose: 1000,
    });
    // Update name and image and massage metadata
    const metadata = {
      name: formData.title,
      description: formData.description,
      image: `${NFT_GATEWAY}${cid}`,
    };
    const additionalData = formData.attributes
      ? formData.attributes.reduce((out, attr) => {
          return { ...out, [attr.name]: attr.value };
        }, {})
      : {};
    // Build and request to sign deploy with signer
    return getMintDeploy({
      metadata: { ...metadata, ...additionalData },
      publicKey: userInfo.publicKey,
    });
  };

  const onCreate = async () => {
    const createFormErrors = validateFormData(formData);
    if (Object.keys(createFormErrors).length) {
      setFormErrors(createFormErrors);
      return;
    }
    const { deployHash } = await executeDeploy(
      buildMintNFTDeploy,
      userInfo.publicKey,
      userInfo.publicKey,
    );
    console.info(deployHash);
  };

  useEffect(() => {
    if (!file) {
      return;
    }
    let reader = new FileReader();
    reader.onloadend = () => {
      setThumb(reader.result);
    };
    reader.readAsDataURL(file);
  }, [file]);

  const storeNFT = async () => {
    toast.loading('Uploading image...', { toastId: toastId });

    const cid = await dispatch(storeFile(file));
    if (!cid || !cid.cid) {
      toast.update(toastId, {
        render: 'Upload fail!',
        type: 'error',
        isLoading: false,
        autoClose: 1000,
      });
    }
    return cid.cid;
  };

  return (
    <>
      <PageTitle title="Create" />

      <section className="container">
        <div className="row">
          <div className="col-lg-7 offset-lg-1 mb-5">
            <CreateForm
              onFileChange={onChange}
              file={file}
              onFormDataChange={(data) => {
                setFormData(data);
                setFormErrors(validateFormData(data));
              }}
              formData={formData}
              onCreate={onCreate}
              isLoading={isUploading || isMinting}
              formErrors={formErrors}
            />
          </div>

          <NFTReview thumb={thumb} title={formData.title} />
        </div>
      </section>
    </>
  );
};

export default Create;
