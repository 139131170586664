import React from 'react';
import CheckboxFilter from '../components/CheckboxFilter';
import ColumnNewThreeColRedux from '../components/ColumnNewThreeColRedux';

const Explore = () => (
  <>
    <section className="container">
      <div className="row">
        <div className="spacer-double"></div>
        <div className="col-md-3">
          <CheckboxFilter />
        </div>
        <div className="col-md-9">
          <ColumnNewThreeColRedux />
        </div>
      </div>
    </section>
  </>
);
export default Explore;
