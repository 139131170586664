import { Axios, Canceler } from '../../../core/axios';
import * as actions from '../../actions';
import api from '../../../core/api';

export const fetchNftsBreakdown = (authorId, isMusic) => async (dispatch) => {
  //access the state
  dispatch(actions.getNftBreakdown.request(Canceler.cancel));

  try {
    let filter = authorId ? 'author=' + authorId : '';
    let music = isMusic ? 'category=music' : '';

    const { data } = await Axios.get(
      `${isMusic ? '/nfts_music.json' : api.nfts}?${filter}&${music}`,
      {
        cancelToken: Canceler.token,
        params: {},
      },
    );

    dispatch(actions.getNftBreakdown.success(data));
  } catch (err) {
    dispatch(actions.getNftBreakdown.failure(err));
  }
};

export const fetchNftShowcase = () => async (dispatch) => {
  dispatch(actions.getNftShowcase.request(Canceler.cancel));

  try {
    const { data } = await Axios.get(`${api.nftShowcases}`, {
      cancelToken: Canceler.token,
      params: {},
    });

    dispatch(actions.getNftShowcase.success(data));
  } catch (err) {
    dispatch(actions.getNftShowcase.failure(err));
  }
};

export const fetchNftDetail = (nftId) => async (dispatch) => {
  dispatch(actions.getNftDetail.request(Canceler.cancel));

  try {
    const { data } = await Axios.get(`${api.nft}/${nftId}`, {
      cancelToken: Canceler.token,
      params: {},
    });

    dispatch(actions.getNftDetail.success(data));
  } catch (err) {
    dispatch(actions.getNftDetail.failure(err));
  }
};

export const storeFile = (image) => async (dispatch) => {
  dispatch(actions.storeFile.request(Canceler.cancel));

  try {
    const formData = new FormData();
    formData.append('image', image);
    const { data } = await Axios.post(`/file/storeFile`, formData, {
      cancelToken: Canceler.token,
    });
    dispatch(actions.storeFile.success(data));
    return data;
  } catch (err) {
    dispatch(actions.storeFile.failure(err));
  }
};

export const fetchMyNFTs = (publicKey, status) => async (dispatch) => {
  dispatch(actions.getMyNFTs.request(Canceler.cancel));
  try {
    const { data } = await Axios.get(`${api.user}/${publicKey}/nfts`, {
      cancelToken: Canceler.token,
      params: { status },
    });

    dispatch(actions.getMyNFTs.success(data));
  } catch (err) {
    dispatch(actions.getMyNFTs.failure(err));
  }
};

// fetch nft info from blockchain
export const fetchMyNftDetail =
  (nftId, nftSmartContract, publicKey) => async (dispatch) => {
    dispatch(actions.getMyNftDetail.request(Canceler.token));

    try {
      const { data } = await Axios.get(
        `${api.nft}/${nftId}/contract/${nftSmartContract}/user/${publicKey}`,
        {
          cancelToken: Canceler.token,
        },
      );

      dispatch(actions.getMyNftDetail.success(data));
    } catch (err) {
      dispatch(actions.getMyNftDetail.failure(err));
    }
  };
