const api = {
  baseUrl: '/mock_data',
  nfts: '/nfts',
  nft: '/nft',
  nftShowcases: '/nft_showcases.json',
  authors: '/authors',
  user: '/user',
  authorsSales: '/author/ranking',
  hotCollections: '/collections',
  blogs: '/blogs',
  blog: '/blog',
};

export default api;
