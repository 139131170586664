import { Axios, Canceler } from '../../../core/axios';
import * as actions from '../index';

export const putDeploy = (deploy) => async (dispatch) => {
  dispatch(actions.putDeploy.request(Canceler.cancel));

  try {
    const { data } = await Axios.post(`/deploy`, deploy, {
      cancelToken: Canceler.token,
    });
    dispatch(actions.storeFile.success(data));
    return data;
  } catch (err) {
    dispatch(actions.storeFile.failure(err));
  }
};
