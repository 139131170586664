import { createSelector } from 'reselect';
import { getBase64IdentIcon } from '../../helpers/identicon';

//TODO: for demo only
const LVL_SYSTEM = [
  { key: 'rock', exp: 10, name: 'Rock' },
  { key: 'silver', exp: 30, name: 'Silver' },
  { key: 'gold', exp: 90, name: 'Gold' },
  { key: 'master', exp: 200, name: 'Master' },
];

const massageNFTDetail = (nft = {}) => {
  const level = nft?.metadata?.find((mdt) => mdt.key === 'level')?.value;
  const lvConfig = LVL_SYSTEM.find((lv) => lv.key === level);
  const nexLvConfig =
    LVL_SYSTEM[LVL_SYSTEM.indexOf(lvConfig) + 1] || LVL_SYSTEM[3];
  return {
    ...nft,
    creatorAvatar: getBase64IdentIcon(nft?.creator),
    ownerAvatar: getBase64IdentIcon(nft?.ownerAccountHash),
    owner: nft?.ownerAccountHash,
    title: nft?.metadata?.find((mdt) => mdt.key === 'name')?.value,
    description: nft?.metadata?.find((mdt) => mdt.key === 'description')?.value,
    image:
      nft?.metadata?.find((mdt) => mdt.key === 'image')?.value ||
      nft?.metadata?.find((mdt) => mdt.key === 'token_uri')?.value ||
      '/img/default-nft.png',
    id:
      typeof nft?.tokenId === 'object'
        ? parseInt(nft?.tokenId?.hex)
        : nft?.tokenId,
    metadata: nft?.metadata?.filter(
      (mtd) => !['image', 'description', 'name', 'token_uri'].includes(mtd.key),
    ),
    contractImage: getBase64IdentIcon(nft?.contractAddress),
    exp: Number(nft?.exp?.toFixed(2)),
    expToNextLevel: Number((nexLvConfig.exp - (nft?.exp || 0)).toFixed(2)),
    level: lvConfig?.name,
    nextLevel: nexLvConfig.name,
    nextLevelPercentage: (nft?.exp / nexLvConfig.exp) * 100,
  };
};

export const myNFTs = (state) => state.NFT.myNFTs;

export const getMyNFTs = createSelector(myNFTs, (nfts) => {
  return nfts.data?.map((datum) => {
    return massageNFTDetail(datum);
  });
});

export const myNFTDetail = (state) => state.NFT.myNFTDetail;

export const getMyNFTDetail = createSelector(myNFTDetail, ({ data }) => {
  return massageNFTDetail(data);
});
