import { combineReducers } from 'redux';
import nftReducer from './nfts';
import hotCollectionsReducer from './hotCollections';
import authorListReducer from './authorList';
import filterReducer from './filters';
import blogPostsReducer from './blogs';
import casperSignerReducer from './casperSigner';
import userReducer from './user';

export const rootReducer = combineReducers({
  NFT: nftReducer,
  hotCollection: hotCollectionsReducer,
  authors: authorListReducer,
  filters: filterReducer,
  blogs: blogPostsReducer,
  user: userReducer,
  signer: casperSignerReducer,
});

const reducers = (state, action) => rootReducer(state, action);

export default reducers;
