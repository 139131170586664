import { Axios, Canceler } from '../../../core/axios';
import * as actions from '../index';

export const hatch =
  (signature, tokenId, contractHash, publicKey, nftInfo) =>
  async (dispatch) => {
    dispatch(actions.hatch.request(Canceler.cancel));

    try {
      const { data } = await Axios.post(
        `/nft/hatch/${tokenId}/${contractHash}`,
        { signature, nftInfo, publicKey },
        {
          cancelToken: Canceler.token,
        },
      );
      dispatch(actions.hatch.success(data));
      return data;
    } catch (err) {
      dispatch(actions.hatch.failure(err));
      return err;
    }
  };

export const stopHatching =
  (signature, tokenId, contractHash, publicKey) => async (dispatch) => {
    dispatch(actions.stopHatching.request(Canceler.cancel));

    try {
      const { data } = await Axios.post(
        `/nft/hatch/stop/${tokenId}/${contractHash}`,
        { signature, publicKey },
        {
          cancelToken: Canceler.token,
        },
      );
      dispatch(actions.stopHatching.success(data));
      return data;
    } catch (err) {
      dispatch(actions.stopHatching.failure(err));
    }
  };
