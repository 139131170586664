/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from 'react';
import Breakpoint, {
  BreakpointProvider,
  setDefaultBreakpoints,
} from 'react-socks';
import { Link, useNavigate, useMatch, useResolvedPath } from 'react-router-dom';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { MiddleTruncatedText } from '../components/common/MiddleTruncatedText';
import {
  getConnectedAccountFromLocalStorage,
  setPublicKey,
} from '../../store/actions/thunks/user';
import { getUserInfo } from '../../store/selectors/user';
import useCasperSigner from '../hooks/useCasperSigner';
import * as actions from '../../store/actions/thunks';

setDefaultBreakpoints([{ xs: 0 }, { l: 1199 }, { xl: 1200 }]);

const NavLink = (props) => {
  let resolved = useResolvedPath(props.to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return <Link {...props} className={match ? 'active' : 'non-active'} />;
};

const Header = function ({ className }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handleConnectCasper } = useCasperSigner();
  const { publicKey, avatar, balance } = useSelector(getUserInfo);

  const [showMenu, btn_icon] = useState(false);
  const [showpop, btn_icon_pop] = useState(false);

  const closePop = () => {
    btn_icon_pop(false);
  };

  const refpop = useOnclickOutside(() => {
    closePop();
  });

  const handleLogout = () => {
    dispatch(setPublicKey());
    navigate('/');
  };

  useEffect(() => {
    const header = document.getElementById('myHeader');
    const totop = document.getElementById('scroll-to-top');
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener('scroll', () => {
      btn_icon(false);
      if (window.pageYOffset > sticky) {
        header.classList.add('sticky');
        totop.classList.add('show');
      } else {
        header.classList.remove('sticky');
        totop.classList.remove('show');
      }
    });
    return () => {
      window.removeEventListener('scroll', scrollCallBack);
    };
  }, []);

  useEffect(() => {
    if (!publicKey) {
      dispatch(getConnectedAccountFromLocalStorage());
    } else {
      dispatch(actions.getUserInfo(publicKey));
    }
  }, [publicKey, dispatch]);

  const handleGotoProfile = () => {
    navigate(`/Author/${publicKey}`);
  };

  return (
    <header className={`navbar white ${className}`} id="myHeader">
      <div className="container">
        <div className="row w-100-nav">
          <div className="logo px-0">
            <div className="navbar-title navbar-item">
              <NavLink to="/">
                <img src="/img/logo.png" className="img-fluid d-4" alt="#" />
              </NavLink>
            </div>
          </div>

          <div className="search">
            <input
              id="quick_search"
              className="xs-hide"
              name="quick_search"
              placeholder="search item here..."
              type="text"
            />
          </div>

          <BreakpointProvider>
            <Breakpoint l down>
              {showMenu && (
                <div className="menu">
                  <div className="navbar-item">
                    <NavLink to="/home" onClick={() => btn_icon(!showMenu)}>
                      Home
                    </NavLink>
                  </div>
                  <div className="navbar-item">
                    <NavLink to="/explorer" onClick={() => btn_icon(!showMenu)}>
                      Home
                    </NavLink>
                  </div>
                  <div className="navbar-item">
                    <NavLink to="/activity" onClick={() => btn_icon(!showMenu)}>
                      Activity
                    </NavLink>
                  </div>
                  <div className="navbar-item">
                    <NavLink to="/ranking" onClick={() => btn_icon(!showMenu)}>
                      Ranking
                    </NavLink>
                  </div>
                  <div className="navbar-item">
                    <NavLink to="/news" onClick={() => btn_icon(!showMenu)}>
                      News
                    </NavLink>
                  </div>
                  <div className="navbar-item">
                    <NavLink to="/create" onClick={() => btn_icon(!showMenu)}>
                      Create
                    </NavLink>
                  </div>
                </div>
              )}
            </Breakpoint>

            <Breakpoint xl>
              <div className="menu">
                <div className="navbar-item">
                  <NavLink to="/home">
                    Home
                    <span className="lines"></span>
                  </NavLink>
                </div>
                <div className="navbar-item">
                  <NavLink to="/explorer">
                    Explorer
                    <span className="lines"></span>
                  </NavLink>
                </div>
                <div className="navbar-item">
                  <NavLink to="/activity">
                    Activity
                    <span className="lines"></span>
                  </NavLink>
                </div>
                <div className="navbar-item">
                  <NavLink to="/ranking">
                    Ranking
                    <span className="lines"></span>
                  </NavLink>
                </div>
                <div className="navbar-item">
                  <NavLink to="/news">
                    News
                    <span className="lines"></span>
                  </NavLink>
                </div>
                {!publicKey && (
                  <div className="navbar-item">
                    <NavLink to="/create">
                      Create
                      <span className="lines"></span>
                    </NavLink>
                  </div>
                )}
              </div>
            </Breakpoint>
          </BreakpointProvider>

          <div className="mainside">
            {!publicKey ? (
              <div className="connect-wal" onClick={handleConnectCasper}>
                <a>Connect Wallet</a>
              </div>
            ) : (
              <div className="logout">
                <NavLink to="/create">Create</NavLink>

                <div
                  id="de-click-menu-profile"
                  className="de-menu-profile"
                  onClick={() => btn_icon_pop(!showpop)}
                  ref={refpop}
                >
                  <img src={avatar} alt="" />
                  {showpop && (
                    <div className="popshow">
                      <div className="d-balance">
                        <h4>Balance</h4>
                        {balance} CSPR
                      </div>
                      <div className="d-wallet">
                        <h4>My Wallet</h4>
                        <span id="wallet" className="d-wallet-address">
                          <MiddleTruncatedText>{publicKey}</MiddleTruncatedText>
                        </span>
                        <button
                          id="btn_copy"
                          title="Copy Text"
                          onClick={() => {
                            navigator.clipboard.writeText(publicKey);
                          }}
                        >
                          Copy
                        </button>
                      </div>
                      <div className="d-line"></div>
                      <ul className="de-submenu-profile">
                        <li onClick={handleGotoProfile}>
                          <span>
                            <i className="fa fa-user"></i> My Profile
                          </span>
                        </li>
                        <li onClick={handleLogout}>
                          <span>
                            <i className="fa fa-sign-out"></i> Sign out
                          </span>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        <button className="nav-icon" onClick={() => btn_icon(!showMenu)}>
          <div className="menu-line white"></div>
          <div className="menu-line1 white"></div>
          <div className="menu-line2 white"></div>
        </button>
      </div>
    </header>
  );
};
export default Header;
