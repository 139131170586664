import { getType } from 'typesafe-actions';
import * as actions from '../actions';

export default function userReducer(
  state = {
    isConnected: false,
    isUnlocked: true,
    error: null,
  },
  action,
) {
  const payload = action.payload;
  switch (action.type) {
    case getType(actions.updateConnectStatus):
      return { ...state, isConnected: payload.isConnected };
    case getType(actions.updateLockStatus):
      return { ...state, isUnlocked: payload.isUnlocked };
    case getType(actions.setConnectError):
      return { ...state, error: payload.error };
    case getType(actions.clearConnectError):
      return { ...state, error: null };
    default:
      return state;
  }
}
