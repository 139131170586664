import React, { memo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Clock from '../components/Clock';
import * as selectors from '../../store/selectors';
import { fetchNftDetail } from '../../store/actions/thunks';
import NFTAttributes from '../components/NFTAttributes';
import { getUserInfo } from '../../store/selectors';
import { getMintDeploy } from '../../services/nftServices';
import { useConfirmDeploy } from '../hooks/useConfirmDeploy';

const ItemDetailRedux = () => {
  const [openMenu0, setOpenMenu0] = React.useState(true);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [openMenu1, setOpenMenu1] = React.useState(false);
  const { nftId } = useParams();
  const userInfo = useSelector(getUserInfo);
  const { executeDeploy, isDeploying: isMinting } = useConfirmDeploy();

  const handleBtnClick0 = () => {
    setOpenMenu0(!openMenu0);
    setOpenMenu(false);
    setOpenMenu1(false);
    document.getElementById('Mainbtn0').classList.add('active');
    document.getElementById('Mainbtn').classList.remove('active');
    document.getElementById('Mainbtn1').classList.remove('active');
  };
  const handleBtnClick = () => {
    setOpenMenu(!openMenu);
    setOpenMenu1(false);
    setOpenMenu0(false);
    document.getElementById('Mainbtn').classList.add('active');
    document.getElementById('Mainbtn1').classList.remove('active');
    document.getElementById('Mainbtn0').classList.remove('active');
  };
  const handleBtnClick1 = () => {
    setOpenMenu1(!openMenu1);
    setOpenMenu(false);
    setOpenMenu0(false);
    document.getElementById('Mainbtn1').classList.add('active');
    document.getElementById('Mainbtn').classList.remove('active');
    document.getElementById('Mainbtn0').classList.remove('active');
  };

  const dispatch = useDispatch();
  const nftDetailState = useSelector(selectors.nftDetailState);
  const nft = nftDetailState.data ? nftDetailState.data : [];

  const [openCheckout, setOpenCheckout] = React.useState(false);
  const [openCheckoutbid, setOpenCheckoutbid] = React.useState(false);

  useEffect(() => {
    dispatch(fetchNftDetail(nftId));
  }, [dispatch, nftId]);

  const buildMintNFTDeploy = async () => {
    // Update name and image and massage metadata
    const metadata = {
      name: nft.title,
      description: nft.description,
      image: nft.preview_image.url,
      ...nft.metadata,
    };

    // Build and request to sign deploy with signer
    return getMintDeploy({ metadata, publicKey: userInfo.publicKey });
  };

  const onBuy = async () => {
    await executeDeploy(
      buildMintNFTDeploy,
      userInfo.publicKey,
      userInfo.publicKey,
    );
  };

  return (
    <>
      <section className="container">
        <div className="row mt-md-5 pt-md-4">
          <div className="col-md-6 text-center">
            <img
              src={nft.preview_image && nft.preview_image.url}
              className="img-fluid img-rounded mb-sm-30"
              alt=""
            />
          </div>
          <div className="col-md-6">
            <div className="item_info">
              {nft.deadline && (
                <>
                  {nft.status === 'on_auction' ? 'Auctions ends in' : 'End in'}
                  <div className="de_countdown">
                    <Clock deadline={nft.deadline} />
                  </div>
                </>
              )}
              <h2>
                {nft.title} - {nft.price?.toLocaleString()} CSPR
              </h2>
              <div className="item_info_counts">
                <div className="item_info_type">
                  <i className="fa fa-image"></i>
                  {nft.category}
                </div>
                <div className="item_info_views">
                  <i className="fa fa-eye"></i>
                  {nft.views}
                </div>
                <div className="item_info_like">
                  <i className="fa fa-heart"></i>
                  {nft.likes}
                </div>
              </div>
              <p>{nft.description}</p>
              <div className="d-flex flex-row">
                <div className="mr40">
                  <h6>Creator</h6>
                  <div className="item_author">
                    <div className="author_list_pp">
                      <span>
                        <img
                          className="lazy"
                          src={nft.author && nft.author.avatar.url}
                          alt=""
                        />
                        <i className="fa fa-check"></i>
                      </span>
                    </div>
                    <div className="author_list_info">
                      <span>{nft.author && nft.author.username}</span>
                    </div>
                  </div>
                </div>
                <div className="mr40">
                  <h6>Collection</h6>
                  <div className="item_author">
                    <div className="author_list_pp">
                      <span>
                        <img
                          className="lazy"
                          src={nft.author && nft.author.avatar.url}
                          alt=""
                        />
                        <i className="fa fa-check"></i>
                      </span>
                    </div>
                    <div className="author_list_info">
                      <span>{nft.collections}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="spacer-40"></div>
              <div className="de_tab">
                <ul className="de_nav">
                  <li id="Mainbtn0" className="active">
                    <span onClick={handleBtnClick0}>Details</span>
                  </li>
                  <li id="Mainbtn">
                    <span onClick={handleBtnClick}>Bids</span>
                  </li>
                  <li id="Mainbtn1" className="">
                    <span onClick={handleBtnClick1}>History</span>
                  </li>
                </ul>

                <div className="de_tab_content">
                  {openMenu0 && (
                    <div className="tab-1 onStep fadeIn">
                      <div className="d-block mb-3">
                        <div className="mr40">
                          <h6>Owner</h6>
                          <div className="item_author">
                            <div className="author_list_pp">
                              <span>
                                <img
                                  className="lazy"
                                  src={nft.author && nft.author.avatar.url}
                                  alt=""
                                />
                                <i className="fa fa-check"></i>
                              </span>
                            </div>
                            <div className="author_list_info">
                              <span>{nft.author && nft.author.username}</span>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-5">
                          {Object.keys(nft.metadata || {}).map((key, i) => {
                            return (
                              <div
                                key={i}
                                className="col-lg-4 col-md-6 col-sm-6"
                              >
                                <NFTAttributes
                                  name={key}
                                  value={nft.metadata[key]}
                                  // rate={rate}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  )}

                  {openMenu && (
                    <div className="tab-1 onStep fadeIn">
                      {nft.bids &&
                        nft.bids.map((bid, index) => (
                          <div className="p_list" key={index}>
                            <div className="p_list_pp">
                              <span>
                                <img
                                  className="lazy"
                                  src={bid.author.avatar.url}
                                  alt=""
                                />
                                <i className="fa fa-check"></i>
                              </span>
                            </div>
                            <div className="p_list_info">
                              Bid{' '}
                              {bid.author.id === nft.author.id && 'accepted'}{' '}
                              <b>{bid.value} CSPR</b>
                              <span>
                                by <b>{bid.author.username}</b> at{' '}
                                {moment(bid.created_at).format('L, LT')}
                              </span>
                            </div>
                          </div>
                        ))}
                    </div>
                  )}

                  {openMenu1 && (
                    <div className="tab-2 onStep fadeIn">
                      {nft.history &&
                        nft.history.map((bid, index) => (
                          <div className="p_list" key={index}>
                            <div className="p_list_pp">
                              <span>
                                <img
                                  className="lazy"
                                  src={bid.author.avatar.url}
                                  alt=""
                                />
                                <i className="fa fa-check"></i>
                              </span>
                            </div>
                            <div className="p_list_info">
                              Bid{' '}
                              {bid.author.id === nft.author.id && 'accepted'}{' '}
                              <b>{bid.value} CSPR</b>
                              <span>
                                by <b>{bid.author.username}</b> at{' '}
                                {moment(bid.created_at).format('L, LT')}
                              </span>
                            </div>
                          </div>
                        ))}
                    </div>
                  )}

                  {/* button for checkout */}

                  {nft.status === 'buy_now' && (
                    <div className="d-flex flex-row mt-5">
                      <button
                        className="btn-main lead mb-5 mr15"
                        onClick={onBuy}
                        disabled={isMinting}
                      >
                        Buy Now
                      </button>
                      {/* <button
                      className="btn-main btn2 lead mb-5"
                      onClick={() => setOpenCheckoutbid(true)}
                    >
                      Place Bid
                    </button> */}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {openCheckout && (
        <div className="checkout">
          <div className="maincheckout">
            <button
              className="btn-close"
              onClick={() => setOpenCheckout(false)}
            >
              x
            </button>
            <div className="heading">
              <h3>Checkout</h3>
            </div>
            <p>
              You are about to purchase a{' '}
              <span className="bold">AnimeSailorClub #304</span>
              <span className="bold">from Monica Lucas</span>
            </p>
            <div className="detailcheckout mt-4">
              <div className="listcheckout">
                <h6>
                  Enter quantity.
                  <span className="color">10 available</span>
                </h6>
                <input
                  type="text"
                  name="buy_now_qty"
                  id="buy_now_qty"
                  className="form-control"
                />
              </div>
            </div>
            <div className="heading mt-3">
              <p>Your balance</p>
              <div className="subtotal">10.67856 CSPR</div>
            </div>
            <div className="heading">
              <p>Service fee 2.5%</p>
              <div className="subtotal">0.00325 CSPR</div>
            </div>
            <div className="heading">
              <p>You will pay</p>
              <div className="subtotal">0.013325 CSPR</div>
            </div>
            <button className="btn-main lead mb-5">Checkout</button>
          </div>
        </div>
      )}
      {openCheckoutbid && (
        <div className="checkout">
          <div className="maincheckout">
            <button
              className="btn-close"
              onClick={() => setOpenCheckoutbid(false)}
            >
              x
            </button>
            <div className="heading">
              <h3>Place a Bid</h3>
            </div>
            <p>
              You are about to purchase a{' '}
              <span className="bold">AnimeSailorClub #304</span>
              <span className="bold">from Monica Lucas</span>
            </p>
            <div className="detailcheckout mt-4">
              <div className="listcheckout">
                <h6>Your bid (CSPR)</h6>
                <input type="text" className="form-control" />
              </div>
            </div>
            <div className="detailcheckout mt-3">
              <div className="listcheckout">
                <h6>
                  Enter quantity.
                  <span className="color">10 available</span>
                </h6>
                <input
                  type="text"
                  name="buy_now_qty"
                  id="buy_now_qty"
                  className="form-control"
                />
              </div>
            </div>
            <div className="heading mt-3">
              <p>Your balance</p>
              <div className="subtotal">10.67856 CSPR</div>
            </div>
            <div className="heading">
              <p>Service fee 2.5%</p>
              <div className="subtotal">0.00325 CSPR</div>
            </div>
            <div className="heading">
              <p>You will pay</p>
              <div className="subtotal">0.013325 CSPR</div>
            </div>
            <button className="btn-main lead mb-5">Checkout</button>
          </div>
        </div>
      )}
    </>
  );
};

export default memo(ItemDetailRedux);
