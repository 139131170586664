import React from 'react';

const Wallet = () => (
  <div className="row">
    <div className="col-lg-3 mb30">
      <span className="box-url">
        <span className="box-url-label">Most Popular</span>
        <img
          src="./img/wallet/casperdash.png"
          alt=""
          className="mb20 wallet-icon"
        />
        <h4>CasperDash</h4>
        <p>
          Open source non-custodial for connecting decentralised applications to
          mobile wallets.
        </p>
      </span>
    </div>

    <div className="col-lg-3 mb30">
      <span className="box-url">
        <span className="box-url-label">Most Simple</span>
        <img src="./img/wallet/8.png" alt="" className="mb20" />
        <h4>Casper Signer</h4>
        <p>
          Open source non-custodial for connecting decentralised applications to
          mobile wallets.
        </p>
      </span>
    </div>
  </div>
);
export default Wallet;
