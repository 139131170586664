import React, { memo } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Clock from './Clock';

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
`;

//react functional component
const NftCard = ({
  nft,
  className = 'd-item col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-4',
  clockTop = true,
  height,
  onImgLoad,
  onClickNFT = () => {},
}) => {
  const navigate = useNavigate();
  const navigateTo = (link) => {
    navigate(link);
  };
  const {
    id,
    nft_link,
    item_type,
    deadline,
    owner,
    ownerAvatar,
    preview_image,
    title,
    priceover,
    price,
    status,
    bid_link,
    bid,
    max_bid,
    likes,
    image,
    author,
  } = nft;

  const clickable = status !== 'minting';

  return (
    <div className={className}>
      <div
        className={`nft__item m-0 ${clickable ? '' : 'cursor-normal'}`}
        onClick={() => (clickable ? onClickNFT(nft) : null)}
      >
        {item_type === 'single_items' ? (
          <div className="icontype">
            <i className="fa fa-bookmark"></i>
          </div>
        ) : (
          <div className="icontype">
            <i className="fa fa-shopping-basket"></i>
          </div>
        )}
        {deadline && clockTop && (
          <div className="de_countdown">
            <Clock deadline={deadline} />
          </div>
        )}
        <div className="author_list_pp">
          <span onClick={() => navigateTo(`/Author/${owner}`)}>
            <img
              className="lazy"
              src={ownerAvatar || author.avatar.url}
              alt=""
            />
            <i className="fa fa-check"></i>
          </span>
        </div>
        <div className="nft__item_wrap" style={{ height: `${height}px` }}>
          <Outer>
            <span>
              <img
                onLoad={onImgLoad}
                src={image || preview_image.url}
                className="lazy nft__item_preview"
                alt=""
              />
            </span>
          </Outer>
        </div>
        {deadline && !clockTop && (
          <div className="de_countdown">
            <Clock deadline={deadline} />
          </div>
        )}
        <div className="nft__item_info">
          <span onClick={() => navigateTo(`${nft_link}/${id}`)}>
            <h4>{title}</h4>
          </span>
          {status === 'has_offers' ? (
            <div className="has_offers">
              <span className="through">{priceover}</span> {price} CSPR
            </div>
          ) : (
            <div className="nft__item_price">
              {price} CSPR
              {status === 'on_auction' && (
                <span>
                  {bid}/{max_bid}
                </span>
              )}
            </div>
          )}
          <div className="nft__item_action">
            <span onClick={() => navigateTo(`${bid_link}/${id}`)}>
              {status === 'on_auction' ? 'Place a bid' : 'Buy Now'}
            </span>
          </div>
          <div className="nft__item_like">
            <i className="fa fa-heart"></i>
            <span>{likes}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(NftCard);
