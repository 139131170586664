import React from 'react';
import { buildClasses } from '../../../../helpers/class';
import './Button.scss';

const Button = ({ children, variant, className, onClick }) => {
  const additionalClasses = buildClasses({ [variant]: true });
  return (
    <div
      className={`btn-main ${additionalClasses} ${className}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default Button;
