import React from 'react';
import emailjs from 'emailjs-com';

const Contact = function () {
  function sendEmail(e) {
    const success = document.getElementById('success');
    const button = document.getElementById('buttonsent');
    const failed = document.getElementById('failed');
    e.preventDefault();

    emailjs
      .sendForm(
        'gmail',
        'template_csfdEZiA',
        e.target,
        'user_zu7p2b3lDibMCDutH5hif',
      )
      .then(
        () => {
          success.classList.add('show');
          button.classList.add('show');
          failed.classList.remove('show');
        },
        () => {
          failed.classList.add('show');
        },
      );
  }

  return (
    <>
      <section className="jumbotron breadcumb no-bg">
        <div className="mainbreadcumb">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h1>Contact Us</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container">
        <div className="row">
          <div className="col-lg-8 mb-3">
            <h3>Do you have any question?</h3>
            <div className="form-side">
              <form className="formcontact" onSubmit={sendEmail}>
                <input
                  type="text"
                  className="form-control"
                  name="user_name"
                  placeholder="Your Name"
                  required
                />
                <input
                  type="email"
                  className="form-control"
                  name="user_email"
                  placeholder="Your Email"
                  required
                />
                <input
                  type="text"
                  className="form-control"
                  name="user_phone"
                  placeholder="Your Phone"
                  required
                />
                <textarea
                  name="message"
                  className="form-control"
                  placeholder="Your Message"
                  required
                />
                <div id="success" className="hide">
                  Your message has been sent...
                </div>
                <div id="failed" className="hide">
                  Message failed...
                </div>
                <input
                  type="submit"
                  id="buttonsent"
                  value="Submit Now"
                  className="btn btn-main color-2"
                />
              </form>
            </div>
          </div>

          <div className="col-md-4">
            <div className="padding40 box-rounded mb30">
              <h3>CasperDash</h3>
              <address className="s1">
                <span>
                  <i className="id-color fa fa-envelope-o fa-lg"></i>
                  <span className="btn">hello@casperdash.io</span>
                </span>
              </address>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Contact;
