import {
  CLValueBuilder,
  CLPublicKey,
  RuntimeArgs,
  DeployUtil,
} from 'casper-js-sdk';
import { toMotes } from '../helpers/currency';
import { NETWORK_NAME, DEPLOY_TTL_MS } from '../constants/key';
import {
  createRecipientAddress,
  toCLMap,
  contractHashToByteArray,
} from './casperServices';

// import { request } from './request';
// import { getConfigKey } from './configurationServices';

/**
 * Get mint NFT deploy
 * @param {CLPublicKey} publicKey
 * @param {object} runtimeArgs
 * @param {string} contractHash
 * @param {number} paymentAmount
 */
export const getMintNFTDeploy = (
  publicKey,
  runtimeArgs,
  contractHash,
  paymentAmount,
  entryPoint = 'mint',
) => {
  return DeployUtil.makeDeploy(
    new DeployUtil.DeployParams(publicKey, NETWORK_NAME, 1, DEPLOY_TTL_MS),
    DeployUtil.ExecutableDeployItem.newStoredContractByHash(
      contractHash,
      entryPoint,
      runtimeArgs,
    ),
    DeployUtil.standardPayment(paymentAmount),
  );
};

/**
 * * Create a NFT transfer deploy
 * @param publicKey - The public key of the account that will be used to deploy the contract.
 * @param runtimeArgs - The arguments to pass to the contract.
 * @param contractHash - The hash of the contract to be deployed.
 * @param entryPoint - The entry point.
 * @param paymentAmount - The amount of tokens to pay for the deploy.
 * @returns The deploy is being returned.
 */
export const getTransferNFTDeploy = (
  publicKey,
  runtimeArgs,
  contractHash,
  entryPoint,
  paymentAmount,
) => {
  return DeployUtil.makeDeploy(
    new DeployUtil.DeployParams(publicKey, NETWORK_NAME, 1, DEPLOY_TTL_MS),
    DeployUtil.ExecutableDeployItem.newStoredContractByHash(
      contractHash,
      entryPoint,
      runtimeArgs,
    ),
    DeployUtil.standardPayment(paymentAmount),
  );
};

/**
 * Get mint nft deploy
 * @param {object} nftInfo
 */
export const getMintDeploy = (nftInfo) => {
  try {
    const {
      recipient,
      metadata,
      publicKey,
      nftContract = process.env.REACT_APP_NFT_CONTRACT,
    } = nftInfo;
    const toAddress = recipient || publicKey;
    const contractHashByteArray = contractHashToByteArray(nftContract.slice(5));
    const pbKey = CLPublicKey.fromHex(publicKey);
    const recipientPK = CLPublicKey.fromHex(toAddress);

    const runtimeArgs = RuntimeArgs.fromMap({
      token_owner: createRecipientAddress(recipientPK),
      token_meta_data: CLValueBuilder.string(JSON.stringify(metadata)),
    });

    return getMintNFTDeploy(
      pbKey,
      runtimeArgs,
      contractHashByteArray,
      toMotes(1),
    );
  } catch (error) {
    console.error(error);
    throw new Error(`Failed to get mint NFT deploy.`);
  }
};

export const getUpdateNFTDeploy = (nftInfo) => {
  try {
    const { metadata, publicKey, nftId, nftSmartContract, entryPoint } =
      nftInfo;
    const contractHashByteArray = contractHashToByteArray(nftSmartContract);
    const pbKey = CLPublicKey.fromHex(publicKey);

    const runtimeArgs = RuntimeArgs.fromMap({
      token_id: CLValueBuilder.u256(nftId),
      token_meta: toCLMap(new Map(metadata)),
    });

    return getMintNFTDeploy(
      pbKey,
      runtimeArgs,
      contractHashByteArray,
      toMotes(1),
      entryPoint,
    );
  } catch (error) {
    console.error(error);
    throw new Error(`Failed to get mint NFT deploy.`);
  }
};
