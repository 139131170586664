import React, { useState } from 'react';
import Button from '../../components/common/Buttons';
import NFTAttributes from '../NFTAttributes';
import './CreateForm.scss';

const CreateForm = ({
  file,
  onFileChange,
  formData,
  onFormDataChange,
  onCreate,
  isLoading,
  formErrors = {},
}) => {
  const { title, attributes = [] } = formData;

  const [attrName, setAttrName] = useState('');
  const [attrValue, setAttrValue] = useState('');
  const [attrError, setAttrError] = useState('');

  const onChangeValue = (e) => {
    const { name, value } = e.target;
    onFormDataChange({ ...formData, [name]: value });
  };

  const validateAttribute = () => {
    if (!attrName || !attrValue) {
      return 'Name and value are required';
    }
    const isExist = attributes.find((attr) => attr.name === attrName);
    if (isExist) {
      return 'Attribute is already exists';
    }
  };

  const onAddAttribute = () => {
    const errorMessage = validateAttribute();
    if (errorMessage) {
      setAttrError(errorMessage);
      return;
    }

    onFormDataChange({
      ...formData,
      attributes: attributes.concat({ name: attrName, value: attrValue }),
    });
    setAttrName('');
    setAttrValue('');
    setAttrError('');
  };

  const onRemoveAttribute = (name) => {
    onFormDataChange({
      ...formData,
      attributes: attributes.filter((attr) => attr.name !== name),
    });
  };

  return (
    <div className="form-border">
      <div className="field-set">
        <h5>Upload file</h5>

        <div className="d-create-file">
          {!file ? (
            <p id="file_name">PNG, JPG, GIF, WEBP or MP4. Max 200mb.</p>
          ) : (
            <p>{file.name}</p>
          )}

          <div className="browse">
            <input
              type="button"
              id="get_file"
              className="btn-main"
              value="Browse"
            />
            <input id="upload_file" type="file" onChange={onFileChange} />
          </div>
        </div>

        <div className="spacer-single"></div>

        <h5>Title</h5>
        <input
          type="text"
          name="title"
          className="form-control"
          placeholder="e.g. 'Pinky Ocean'"
          value={title}
          onChange={(e) => onChangeValue(e)}
        />
        {formErrors.title && (
          <div className="error-message">{formErrors.title}</div>
        )}
        <div className="spacer-20"></div>

        <h5>Description</h5>
        <textarea
          data-autoresize
          name="description"
          className="form-control"
          placeholder="e.g. 'This is very limited item'"
          onChange={(e) => onChangeValue(e)}
        ></textarea>

        <div className="spacer-20"></div>

        <h5>Attributes</h5>
        <div className="row">
          {attributes.map(({ name, value }) => {
            return (
              <div key={name} className="col-lg-4 col-md-6 col-sm-6">
                <NFTAttributes
                  key={name}
                  name={name}
                  value={value}
                  onRemove={onRemoveAttribute}
                />
              </div>
            );
          })}
        </div>
        <div className="spacer-10"></div>
        <div className="properties">
          <div className="props-row">
            <h6>Name</h6>
            <input
              type="text"
              className="form-control"
              placeholder="color"
              value={attrName}
              onChange={(e) => {
                attrError && setAttrError('');
                setAttrName(e.target.value);
              }}
            />
          </div>
          <div className="props-row">
            <h6>Value</h6>
            <input
              type="text"
              className="form-control"
              placeholder="white"
              value={attrValue}
              onChange={(e) => {
                attrError && setAttrError('');
                setAttrValue(e.target.value);
              }}
            />
          </div>
          <Button
            className="btn-add"
            variant="outline"
            onClick={onAddAttribute}
          >
            Add
          </Button>
        </div>
        {attrError && <div className="error-message">{attrError}</div>}
        <div className="spacer-20"></div>
        <button className="btn-main" onClick={onCreate} disabled={isLoading}>
          Mint
        </button>
      </div>
    </div>
  );
};

export default CreateForm;
